import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './auth/login/login.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { AuthenticationGuard } from './auth/guards/authentication.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Role } from './shared/constants/role.constants';

const FULL_ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
  }
];

const CONTENT_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'admin', component: AdminLayoutComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [Role.ADMIN],
        redirectTo: 'home',
      }
    },
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: 'userTarget',
    loadChildren: () => import('./modules/user-target/user-target.module').then((m) => m.UserTargetModule),
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [Role.ADMIN, Role.COORDINATOR],
        redirectTo: 'home',
      }
    },
  },
  {
    path: 'salesOpportunity',
    loadChildren: () => import('./modules/sales-opportunity/sales-opportunity.module').then((m) => m.SalesOpportunityModule),
  },
  {
    path: 'calendar',
    loadChildren: () => import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
  }
];
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: CONTENT_ROUTES,
    canActivate: [AuthenticationGuard],
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: FULL_ROUTES
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      // useHash: true
    })
  ],
  exports:
    [RouterModule],
})
export class AppRoutingModule { }
